import { TabContext } from "@material-ui/lab";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TabsButtons = ({ investment, page }) => {
  const navigate = useNavigate();

  return (
    <>
      {page === "invest" ? (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={""}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #007BBD",
                    bgcolor:
                      investment === "mutual_fund" ? "#ffffff" : "#007BBD",
                    color: investment === "mutual_fund" ? "#007BBD" : "#ffffff",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "transparent",
                      border: "1px solid #007BBD",
                      color: "#007BBD",
                    },
                  }}
                  onClick={() =>
                    navigate("/dashboardAdmin/nse/multi/order/:folio")
                  } // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                {/* <Button
              sx={{
                textTransform: "none",
                bgcolor: "#007BBD",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "1px solid #007BBD",
                  color: "#007BBD",
                  borderRadius: "10px",
                },
              }}
              onClick={() => navigate("/dashboardAdmin/fd-transactions")} // Redirect to FdTransactions component
            >
              Fixed Deposit
            </Button> */}

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #007BBD",
                    bgcolor: investment === "gold" ? "#ffffff" : "#007BBD",
                    color: investment === "gold" ? "#007BBD" : "#ffffff",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "transparent",
                      border: "1px solid #007BBD",
                      color: "#007BBD",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/goldInvesting")} // Replace with your actual route
                >
                  Gold
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={2} xs>
          <Box>
            <TabContext value={"2"}>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  mb: "15px",
                  mt: "30px",
                  mx: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #007BBD",
                    bgcolor:
                      investment === "mutual_fund" ? "#ffffff" : "#007BBD",
                    color: investment === "mutual_fund" ? "#007BBD" : "#ffffff",

                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "transparent",
                      border: "1px solid #007BBD",
                      color: "#007BBD",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/investing")} // Replace with your actual route
                >
                  Mutual Funds
                </Button>

                {/* <Button
              sx={{
                textTransform: "none",
                bgcolor: "#007BBD",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "1px solid #007BBD",
                  color: "#007BBD",
                  borderRadius: "10px",
                },
              }}
              onClick={() => navigate("/dashboardAdmin/fd-transactions")} // Redirect to FdTransactions component
            >
              Fixed Deposit
            </Button> */}

                <Button
                  sx={{
                    textTransform: "none", // Prevents the text from becoming uppercase
                    border: "1px solid #007BBD",
                    bgcolor: investment === "gold" ? "#ffffff" : "#007BBD",
                    color: investment === "gold" ? "#007BBD" : "#ffffff",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "transparent",
                      border: "1px solid #007BBD",
                      color: "#007BBD",
                    },
                  }}
                  onClick={() => navigate("/dashboardAdmin/goldInvestment")} // Replace with your actual route
                >
                  Gold
                </Button>
              </Stack>
            </TabContext>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default TabsButtons;
