import React, { useEffect, useState } from "react";
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  Alert,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { FormatNumber, FormatNumberRedeem } from "../../../utils/formatNumber";
import Loading from "../../Dashboard/Loading";
import TextField from "@mui/material/TextField";
import Datefilter from "../../../utils/Datefilter";
import CardsMobileRes from "../CardMobileRes";

export default function TransactionDateWise({ accessToken }: any) {
  const [tranx, setTranx] = useState<any>([]);
  const [selectedDate, handleDateChange] = React.useState(null);
  const { folio_id } = useParams();
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [columns, setColumns] = useState([
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      renderHeader: () => <strong>{"S.No"}</strong>,
    },
    {
      field: "metalType",
      headerName: "Gold / Silver",
      width: 380,
      renderHeader: () => <strong>{"Metal"}</strong>,
      renderCell: (params) => {
        let { metalType } = params.row;
        return (
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              width: "100%",
              textAlign: "center", // Center text inside the div
            }}
          >
            {metalType}
          </div>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 240,
      renderHeader: () => <strong>{"Transaction Type"}</strong>,
      renderCell: (params) => {
        let { transaction_type } = params.row;
        // const TransactionType = transaction_type.includes("Purchase") ? "Purchase" : transaction_type.includes("Redemption") ? "Redemption" : "";

        return <div>{transaction_type}</div>;
      },
    },
    {
      field: "UNITS",
      headerName: "Amount",
      width: 180,
      renderHeader: () => <strong>{"Amount / Units"}</strong>,
      renderCell: (params) => {
        const {
          purchase_quantity, // New key for quantity
          purchase_amount, // New key for amount
          sell_quantity,
          sell_amount,
          status,
          purchaseType,
          transaction_type,
        } = params.row;

        let transaction_status: string = "";

        let valueToDisplay: string = "";

        if (transaction_type === "Buy") {
          switch (status) {
            case 0:
              transaction_status = "Pending";
              break;
            case 1:
              transaction_status = "Success";
              break;
            case 2:
              transaction_status = "Cancel";
              break;
          }

          valueToDisplay =
            purchaseType === "quantity"
              ? `${FormatNumber(purchase_quantity)} units`
              : `Rs ${FormatNumber(purchase_amount)}`;
        }

        if (transaction_type === "Sell") {
          switch (status) {
            case 3:
              transaction_status = "Pending";
              break;
            case 4:
              transaction_status = "Success";
              break;
            case 5:
              transaction_status = "Cancel";
              break;
          }

          valueToDisplay =
            purchaseType === "quantity"
              ? `${FormatNumber(sell_quantity)} units`
              : `Rs ${FormatNumber(sell_amount)}`;
        }

        // Determine which value to display: quantity or amount

        // Redemption logic (for completeness)
        // const amountDisplay =
        //   TRXN_TYPE === "Redemption" && ALL_UNITS === "Yes"
        //     ? "All units"
        //     : valueToDisplay;

        // Conditional rendering based on transaction status
        let statusColor = "green";

        if (status === 0) statusColor = "#FDD017";
        else if (status === 2 || status === 5) statusColor = "red";

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{valueToDisplay}</Typography>
            <Typography
              sx={{ color: statusColor, textWrap: "wrap" }}
              variant="caption"
            >
              {transaction_status}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Transaction Date",
      width: 240,
      renderHeader: () => <strong>{"Transaction Date"}</strong>,
      renderCell: (params) => {
        let { createdAt } = params.row;
        createdAt = new Date(createdAt).toLocaleDateString();
        return (
          <div
            style={{
              minHeight: "50px",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "center", // Center horizontally
            }}
          >
            {createdAt}
          </div>
        );
      },
    },
  ]);

  const [loading, setLoading] = useState(false);

  const dateConverter = (str) => {
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(str);
    var mnth = date.getMonth();
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${day}-${month[mnth]}-${year}`;
  };

  const [isDisable, SetisDisable] = useState(true);

  const [IntervalDate, setIntervalDate] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const [Interval, setInterval] = useState({
    startDate: dateConverter(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    endDate: dateConverter(new Date().getTime()),
  });

  const getTranxData = async () => {
    setLoading(true);
    const startDate = new Date(IntervalDate.startDate).getTime();
    const endDate = new Date(IntervalDate.endDate).getTime();
    const diff = endDate - startDate;
    const ThirtyDays = 1000 * 60 * 60 * 24 * 30;
    console.log(endDate - startDate);
    if (diff > ThirtyDays) {
      setLoading(false);
      setMessage("Difference between the dates cannot be more than 30 days");
      setError(true);
      return;
    }

    // Adjust the end date to the end of the selected day
    const endOfDay = new Date(IntervalDate.endDate);
    endOfDay.setHours(23, 59, 59, 999);

    try {
      const res = axios
        .post(
          `${process.env.REACT_APP_BACKEND_HOST}v1/gold/transactions`,
          {
            from_date: new Date(IntervalDate.startDate),
            to_date: endOfDay,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          setLoading(false);
          console.log(data);
          if (!data.succ) {
            setError(true);
            if (data.message == "Selected date difference Exceed 60 days") {
              setMessage(
                "Difference between the dates cannot be more than 60 days"
              );
            } else {
              setMessage(data.message);
            }

            setTranx(data.data);
            return;
          }
          setTranx(data.data);
        });
    } catch {
      setLoading(false);
      setError(true);
      setTranx([]);
      setMessage("Failed to Fetching Data from Server");
      return;
    }
    SetisDisable(true);
  };

  useEffect(() => {
    getTranxData();
  }, []);

  const changeHandler = (name, value) => {
    setIntervalDate((prev) => ({
      ...prev,
      [name]: dateConverter(value),
    }));
  };

  return (
    <Grid container spacing={2} xs>
      <Grid item xs={12} sx={{ ml: { xs: 0, md: 4 }, height: "100vh" }}>
        <Snackbar
          open={isError}
          autoHideDuration={4000}
          onClose={() => setError(false)}
        >
          <Alert severity="error" style={{ backgroundColor: "red" }}>
            <span style={{ color: "white" }}>{message}</span>
          </Alert>
        </Snackbar>
        <AppBar
          sx={{
            backgroundColor: "white",
            marginLeft: { xs: "5%", md: 0 },
            display: "flex",
            width: { xs: "96vw", md: "81.5vw" },
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          position="static"
          elevation={0}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "0px",
              padding: 0,
            }}
          >
            <Datefilter
              name="startDate"
              label="From Date"
              value={IntervalDate.startDate}
              changeHandler={changeHandler}
              maxDate={Interval.endDate}
              minDate={Interval.startDate}
              inputFormat="dd-MMM-yyyy"
            />
            <Datefilter
              name="endDate"
              label="To Date"
              value={IntervalDate.endDate}
              changeHandler={changeHandler}
              maxDate={Interval.endDate}
              minDate={Interval.startDate}
              inputFormat="dd-MMM-yyyy"
            />
            <Button
              sx={{ marginTop: "-3px", height: "37px" }}
              variant="contained"
              color="primary"
              onClick={getTranxData}
            >
              Search
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            height: "85vh",
            display: { xs: "none", sm: "block" },
            width: { xs: "96.5vw", md: "80vw" },
            ml: { xs: 3, sm: 0 },
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <DataGrid
              sx={{ mt: 1 }}
              getRowHeight={() => "auto"}
              //  hideFooter={true}
              rowsPerPageOptions={[50, 100, 1000]}
              // rows={rows}
              rows={tranx?.map((each: any, idx: number) => {
                return { ...each, id: idx + 1 };
              })}
              columns={columns.map((each, idx) => {
                return {
                  id: idx + 1,
                  ...each,
                  headerAlign: "center",
                  align: "center",
                  sx: {
                    size: 2,
                  },
                };
              })}
            />
          )}
        </Box>
        {/* {loading ? (
          <Loading />
        ) : (
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginLeft: "2.5vw",
            }}
          >
            {tranx?.map((ele) => {
              return (
                <CardsMobileRes
                  scheme={ele.SCHEME_NAME?.split("/")[1]}
                  Amount={ele.AMOUNT}
                  date={ele.AUTHORIZED_DATE_TIME}
                  TranType={ele.TRXN_TYPE}
                  TranStatus={ele.TRXN_STATUS}
                  setFormData={""}
                  setIsModel={""}
                />
              );
            })}
          </Box>
        )} */}
      </Grid>
    </Grid>
  );
}
