import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Dashboard/Loading";
import {
  Divider,
  Paper,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Button,
  Box,
} from "@mui/material";
import { FormatNumber, ConvertToPercentage } from "../../utils/formatNumber";
import { useAppContext } from "../../Store/AppContext";
import DefaultPage from "./DefaultPage";
import HoverData from "../Main/HoverData";
import TabsButtons from "./TabsButtons";

export default function Investment(props: any) {
  const [storeState, dispatch] = useAppContext();
  const [tranx, setTranx] = useState<any>([]);
  const [Data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [msg, setMsg] = useState("");
  const [isFail, setIsFail] = useState(false);
  const [NavList, setNavList] = useState<any>([]);
  const [totalSum, setTotalSum] = useState({
    totalInvested: 0,
    totalCurrentValue: 0,
    totalEarnedYesterDay: 0,
    totalEarnedTillDate: 0,
  });
  const [yesterdayEarnedBreakup, setYesterdayEarnedBreakup] = useState([]);
  const navigate = useNavigate();

  const getTransactionReports = async () => {
    storeState.ACTIVEINVETOR?.folio.pan &&
      (await axios
        .get(
          `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/transactionreport`,
          {
            params: {
              pan: storeState.ACTIVEINVETOR?.folio.pan,
            },
            headers: {
              Authorization: `Bearer ${props.accessToken}`,
            },
          }
        )
        .then(async (res) => {
          const { data } = res;
          if (!data.succ) {
            setIsFail(true);
            setMsg(data.message);
            setData({});
            setTotalSum({
              totalCurrentValue: 0,
              totalEarnedTillDate: 0,
              totalEarnedYesterDay: 0,
              totalInvested: 0,
            });
            setEmpty(false);
            return;
          }
          const { Data, TotalEarn, yesterdayEarnedBreakup } = data;
          setYesterdayEarnedBreakup(yesterdayEarnedBreakup);
          if (Object.keys(Data).length > 0) {
            setData(Data);
            setTotalSum((totalSum) => ({
              ...totalSum,
              ...TotalEarn,
            }));
          } else {
            setData({});
            setTotalSum({
              totalCurrentValue: 0,
              totalEarnedTillDate: 0,
              totalEarnedYesterDay: 0,
              totalInvested: 0,
            });
            setEmpty(false);
            return;
          }
        })
        .catch((error) => {
          setData({});
          setTotalSum({
            totalCurrentValue: 0,
            totalEarnedTillDate: 0,
            totalEarnedYesterDay: 0,
            totalInvested: 0,
          });
          setEmpty(false);
          setMsg(error);
          return;
        }));
  };

  useEffect(() => {
    setLoading(true);
    getTransactionReports();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [storeState.ACTIVEINVETOR]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return loading ? (
    <Loading />
  ) : storeState.ACTIVEINVETOR?.isIINVerifid ? (
    <>
      <TabsButtons investment={"mutual_fund"} page={"home"} />
      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }} xs>
        <Snackbar
          open={isFail}
          autoHideDuration={5000}
          onClose={() => setIsFail(false)}
        >
          <Alert severity="error">{msg}</Alert>
        </Snackbar>
        {!storeState?.USER.isAdminApproved && (
          <Grid
            sx={{
              display: "flex",
              position: "fixed",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "-20px",
              zIndex: "10",
              background: "#F0E68D",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <Typography variant="body1">
              Thank you for uploading your details.We will compelete your KYC
              and create your account using these
            </Typography>
            <Typography variant="body1">
              details. Meanwhile for any queries, please reach out at 9818373388
              or shikher@klarfin.com
            </Typography>
          </Grid>
        )}
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h6" sx={{ mb: 2 }}>
            Overview
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minWidth: "69vw",
            }}
          >
            <Grid
              sx={{ display: "flex", flexDirection: { sm: "row", xs: "row" } }}
            >
              <Paper
                elevation={2}
                sx={{
                  padding: "16px",
                  textAlign: "center",
                  width: { sm: "50%", xs: "50%" },
                }}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Total Invested
                </Typography>
                <Typography sx={{ fontSize: { sm: "2.2rem", xs: "1.3rem" } }}>
                  {FormatNumber(totalSum.totalInvested)}
                </Typography>
              </Paper>
              <Paper
                elevation={2}
                sx={{
                  padding: "16px",
                  textAlign: "center",
                  width: { sm: "50%", xs: "50%" },
                }}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Current Value
                </Typography>
                <Typography sx={{ fontSize: { sm: "2.2rem", xs: "1.3rem" } }}>
                  {FormatNumber(totalSum.totalCurrentValue)}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              sx={{ display: "flex", flexDirection: { sm: "row", xs: "row" } }}
            >
              <Paper
                elevation={2}
                sx={{
                  padding: "16px",
                  textAlign: "center",
                  width: { sm: "50%", xs: "50%" },
                  position: "relative",
                }}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Money Earned Yesterday
                </Typography>
                <Typography sx={{ fontSize: { sm: "2.2rem", xs: "1.3rem" } }}>
                  {FormatNumber(totalSum.totalEarnedYesterDay)}
                </Typography>
                {/* {isHovered && (<HoverData data={yesterdayEarnedBreakup} />)} */}
              </Paper>
              <Paper
                elevation={2}
                sx={{
                  padding: "16px",
                  textAlign: "center",
                  width: { sm: "50%", xs: "50%" },
                }}
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Returns through Klarfin till Date
                </Typography>
                <Typography sx={{ fontSize: { sm: "2.2rem", xs: "1.3rem" } }}>
                  {FormatNumber(totalSum.totalEarnedTillDate)}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={2} sx={{ p: { xs: 2, sm: 4 }, pl: 6, pr: 6 }}>
          <Typography style={{ fontWeight: 600 }} variant="h6" sx={{ mb: 2 }}>
            Scheme Wise Report
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              overflow: { xs: "scroll", sm: "hide" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                minWidth: { xs: "50rem", sm: "69vw" },
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Scheme
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Amount Invested
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2.5}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Current / Market Value
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{ fontWeight: 600, color: "grey" }}
                  variant="body1"
                >
                  Actions
                </Typography>
              </Grid>
            </Box>
            <Divider
              sx={{ mb: 2, mt: 3, minWidth: { xs: "50rem", sm: "69vw" } }}
            />
            {!isEmpty &&
              Object.entries(Data)?.map((each, idx) => {
                const key = each[0];
                const value: any = each[1];
                return (
                  <>
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        minWidth: { xs: "50rem", sm: "69vw" },
                        overflowX: { xs: "scroll", sm: "hidden" },
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography style={{ fontWeight: 600 }} variant="body1">
                          {key}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.5}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="h6">
                            {FormatNumber(value.purchaseAmount)}
                          </Typography>
                          <Typography
                            variant="caption"
                            color={value.gain < 0 ? "red" : "#32CD32"}
                            sx={{ textAlign: "end" }}
                          >
                            {value.gain < 0
                              ? FormatNumber(value?.gain)
                              : `+ ${FormatNumber(value?.gain)}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            color={
                              value.xirr_percentage < 0 ? "red" : "#32CD32"
                            }
                            sx={{ textAlign: "end" }}
                          >
                            {value.xirr_percentage < 0
                              ? `XIRR : ${FormatNumber(
                                  value?.xirr_percentage
                                )}%`
                              : `XIRR : +${FormatNumber(
                                  value?.xirr_percentage
                                )}%`}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.5}
                        display="flex"
                        justifyContent="center"
                      >
                        <Typography variant="h6">
                          {FormatNumber(value.currentValue)}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        sm={6}
                        md={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              width: "100px",
                              borderRadius: "5px",
                              textAlign: "center",
                              color: "white",
                              backgroundColor: "#ffa500",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              navigate(
                                `/dashboardAdmin/nse/order/${storeState.ACTIVEINVETOR.folio.Folio}`,
                                { state: { value } }
                              )
                            }
                          >
                            {" "}
                            Buy More
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              width: "100px",
                              borderRadius: "5px",
                              textAlign: "center",
                              color: "white",
                              backgroundColor: "#318cd6",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              navigate(
                                `/dashboardAdmin/nse/redeem/${storeState.ACTIVEINVETOR.folio.Folio}`,
                                { state: { value } }
                              )
                            }
                          >
                            {" "}
                            Redeem{" "}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    {idx !== tranx.length - 1 && (
                      <Divider
                        sx={{
                          mt: 2,
                          mb: 2,
                          minWidth: { xs: "50rem", sm: "69vw" },
                        }}
                      />
                    )}
                  </>
                );
              })}
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                flexDirection: "column",
                gap: 3,
                sm: "none",
                p: 0,
              },
            }}
          >
            {!isEmpty &&
              Object.entries(Data)?.map((each, idx) => {
                const key = each[0];
                const value: any = each[1];
                return (
                  <>
                    <Paper elevation={1} sx={{ padding: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        gap={1}
                      >
                        <Typography
                          style={{ fontWeight: 600, color: "grey" }}
                          variant="body1"
                        >
                          Scheme
                        </Typography>
                        <Typography style={{ fontWeight: 600 }} variant="body1">
                          {key}
                        </Typography>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        display="flex"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <Typography
                            style={{ fontWeight: 600, color: "grey" }}
                            variant="body1"
                          >
                            Amount Invested
                          </Typography>
                        </Grid>
                        <Grid item xs={6} display="flex" alignItems="center">
                          {value.gain < 0 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="body1"
                              >
                                {FormatNumber(value.purchaseAmount)}
                              </Typography>
                              <Typography
                                color="red"
                                sx={{ textAlign: "end", fontSize: "13px" }}
                              >
                                {FormatNumber(value.gain)}
                              </Typography>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="body1"
                              >
                                {FormatNumber(value.purchaseAmount)}
                              </Typography>
                              <Typography
                                color="#32CD32"
                                sx={{ textAlign: "end", fontSize: "13px" }}
                              >
                                +{FormatNumber(value.gain)}
                              </Typography>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        gap={2}
                      >
                        <Grid item xs={6}>
                          <Typography
                            style={{ fontWeight: 600, color: "grey" }}
                            variant="body1"
                          >
                            Current/Market Value
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{ fontWeight: 600 }}
                            variant="body1"
                          >
                            {FormatNumber(value.currentValue)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                      <Grid item xs={12} display="flex" alignItems="center">
                        <Grid item xs={6}>
                          <Typography
                            style={{ fontWeight: 600, color: "grey" }}
                            variant="body1"
                          >
                            Actions
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              padding: "5px",
                              width: "80px",
                              borderRadius: "5px",
                              textAlign: "center",
                              color: "white",
                              backgroundColor: "#ffa500",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              navigate(
                                `/dashboardAdmin/nse/order/${storeState.ACTIVEINVETOR.folio.Folio}`,
                                { state: { value } }
                              )
                            }
                          >
                            {" "}
                            Buy More
                          </div>
                          <div
                            style={{
                              padding: "5px",
                              width: "80px",
                              borderRadius: "5px",
                              textAlign: "center",
                              color: "white",
                              backgroundColor: "#318cd6",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              navigate(
                                `/dashboardAdmin/nse/redeem/${storeState.ACTIVEINVETOR.folio.Folio}`,
                                { state: { value } }
                              )
                            }
                          >
                            {" "}
                            Redeem{" "}
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                );
              })}
          </Box>
        </Paper>
      </Grid>
    </>
  ) : (
    <DefaultPage accessToken={props.accessToken} />
  );
}
