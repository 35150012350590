import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios from "axios";
import { useAppContext } from "../../../Store/AppContext";

const KycModal = ({
  isKycOpen,
  setIsKycOpen,
  userData,
  panData,
  accessToken,
}: any) => {
  const [pan, setPan] = useState<string>(panData ? panData : "");
  const [file, setFile] = useState<string>("");
  const [dob, setDob] = useState<string>(
    userData && userData.dob
      ? new Date(userData.dob).toISOString().split("T")[0]
      : ""
  );

  const [name, setName] = useState<string>(
    userData?.name ? userData?.name : ""
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleClose = () => {
    setIsKycOpen(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("panNumber", pan);
    formData.append("doc_pan", file);
    formData.append("dateOfBirth", dob);
    formData.append("nameAsPerPan", name);

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/kyc`,
        formData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("Kyc response ->", response.data);
      handleClose();
    } catch (error) {
      console.log("Account Details Error ->", error);
    }
  };

  return (
    <Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        open={isKycOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Kyc Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Pan Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={userData.pan ? userData.pan.panNumber : pan}
              onChange={(e) => setPan(e.target.value)}
            />

            <TextField
              type="file"
              id="outlined-basic"
              label="Pan Attachment"
              //   variant="outlined"
              focused
              fullWidth
              margin="normal"
              onChange={handleFileChange}
            />

            <TextField
              type="date"
              id="outlined-basic"
              label="Date of Birth"
              //   variant="outlined"
              focused
              margin="normal"
              fullWidth
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Name As Per Pan"
              variant="outlined"
              fullWidth
              margin="normal"
              value={userData.pan ? userData.pan.nameAsPerPan : name}
              onChange={(e) => setName(e.target.value)}
            />
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default KycModal;
