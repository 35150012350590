import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { SocketContext } from "../../../Store/SocketContext";
import {
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { metalType, purchaseType } from "../../../Data/data";
import { load } from "@cashfreepayments/cashfree-js";
import TabsButtons from "../TabsButtons";

const BuyGoldSilver = ({ user, accessToken }) => {
  const { socketData } = useContext(SocketContext);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState<any>();
  const [price, setPrice] = useState<string>("0");
  const [type, setType] = useState<any>("");
  const [qty, setQty] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [cashFree, setCashFree] = useState<any>(null);
  const [lockPrice, setLockPrice] = useState<any>("");
  const [blockId, setBlockId] = useState<string>("");

  useEffect(() => {
    if (socketData) {
      const gold_price = socketData.gBuy + socketData.gBuyGst;
      const silver_price = socketData.sBuy + socketData.sBuyGst;

      switch (type) {
        case "gold":
          setPrice(gold_price);
          break;
        case "silver":
          setPrice(silver_price);
          break;
      }

      setBlockId(socketData.block_id);
      setLockPrice(
        type === "gold"
          ? socketData.gBuy
          : type === "silver"
          ? socketData.sBuy
          : ""
      );

      if (selectedPurchaseType === "quantity" && qty > 0) {
        setTotalAmount(+parseFloat(gold_price).toFixed(2) * qty);
      }
    }
  }, [socketData, type]);

  const handleChangePurchaseType = (e: any) => {
    let { value } = e.target;
    setSelectedPurchaseType(value);
    setQty(0);
  };

  const handleChangeAmount = (e: any) => {
    let { value } = e.target;
    setQty(value);

    if (selectedPurchaseType === "amount") {
      setTotalAmount(+value);
    }
    if (selectedPurchaseType === "quantity") {
      setTotalAmount(+price * value);
    }
  };

  useEffect(() => {
    const fetchCashFree = async () => {
      try {
        const data = await load({ mode: "sandbox" });
        setCashFree(data); // Store the loaded data in state
        // Handle the cashFree data here
      } catch (error) {
        // Handle any errors here
        console.error("Error loading CashFree:", error);
      }
    };

    fetchCashFree();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      console.log("totalAmount ->", totalAmount);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/buy`,
        {
          blockId,
          lockPrice,
          metalType: type,
          purchaseType: selectedPurchaseType,
          quantity: selectedPurchaseType === "quantity" ? qty : null,
          amount: selectedPurchaseType === "amount" ? qty : null,
          orderAmount: totalAmount.toFixed(2),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const { payment_session_id } = response.data.data; // Get payment_session_id from response

      console.log(payment_session_id);

      if (payment_session_id) {
        const options = {
          paymentSessionId: payment_session_id,
        };

        cashFree.checkout(options);
      }
    } catch (error) {
      console.error(error);
      alert("Error initiating payment");
    }
  };

  const handleChangeMetalType = (e: any) => {
    const { value } = e.target;

    setType(value);
    setSelectedPurchaseType(null);
    setQty(0);
    setTotalAmount(0);
  };

  return (
    <>
      <TabsButtons investment={"gold"} page={"invest"} />

      <div style={{ margin: "2rem" }}>
        <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
          <CardContent>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <TextField
                label="Metal"
                name="Metal"
                onChange={(e) => handleChangeMetalType(e)}
                // value={
                //   // formData.TransactionData[0].Scheme
                //   value.name !== undefined
                //     ? value.Product_code
                //     : formData.TransactionData[0].Scheme
                // }
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {metalType?.map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                type="text"
                label={type !== "" ? "" : "Price"}
                name="Price"
                variant="outlined"
                fullWidth
                margin="normal"
                value={type !== "" ? parseFloat(price).toFixed(2) : 0}
                autoFocus={true}
                // onChange={(e) => setPrice(e.target.value)}
                disabled={type !== "" ? true : false}
              />

              <TextField
                label="Purchase Type"
                name="Purchase Type"
                onChange={handleChangePurchaseType}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {purchaseType.map((ele: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={ele.value}>
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                type="number"
                id="outlined-basic"
                label={
                  selectedPurchaseType === "amount" ? "Amount" : "Quantity"
                }
                variant="outlined"
                fullWidth
                margin="normal"
                value={qty}
                autoFocus
                onChange={handleChangeAmount}
              />
              <Typography sx={{ textAlign: "end" }}>
                Total Amount : {totalAmount ? totalAmount.toFixed(2) : "0.00"}{" "}
                (Included with GST)
              </Typography>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "INVEST"
                )}
              </Button>
            </form>
          </CardContent>
          {/* <Snackbar
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => setIsSuccess(false)}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="success">{msg}</Alert>
        </Snackbar>
        <Snackbar
          open={isFailure}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="error">{msg}</Alert>
        </Snackbar> */}
        </Card>
        {/* {modelName == "TR" && (
        <div>
          <RTGSPayment
            state={state}
            setValidationErrors={setValidationErrors}
            validationErrors={validationErrors}
            isLoading={isLoading}
            setFormData={setFormData}
            formData={formData}
            open={open}
            setOpen={setOpen}
            setMsg={setMsg}
            setIsFailure={setIsFailure}
            setIsLoading={setIsLoading}
            setIsSuccess={setIsSuccess}
            accessToken={accessToken}
          />
        </div>
      )} */}
        {/* {modelName == "OL" && isSuccess && (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -75%)",
                bgcolor: "background.paper",
                borderRadius: "10px",
                minWidth: "40vw",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" m={2}>
                Online Payment Link
              </Typography>
              <Divider sx={{ mb: 2, color: "blue" }} />
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Typography id="modal-modal-description">
                  Kindly check your email for the payment link
                </Typography>
              )}
            </Box>
          </Modal>
        </div>
      )} */}
      </div>
    </>
  );
};

export default BuyGoldSilver;
