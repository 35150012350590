import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Alert, Autocomplete, Box, Snackbar, TextField } from "@mui/material";
import axios from "axios";
const AccountModal = ({
  openAccount,
  setOpenAccount,
  accessToken,
  userData,
  userAddress,
  setIsUpdated,
  setSuccess,
  setError,
  setMsg,
}: any) => {
  const [name, setName] = useState<string>(userData ? userData.name : "");
  const [mobile, setMobile] = useState<string>(
    userData ? userData.mobileNumber : ""
  );
  const [email, setEmail] = useState<string>(userData ? userData.email : "");
  const [address, setAddress] = useState<string>(
    userAddress ? userAddress.address : ""
  );
  const [pincode, setPincode] = useState<string>(
    userAddress ? userAddress.pincode : ""
  );
  const [cities, setCities] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [selectedCity, setSelectedCity] = useState<any>(
    userAddress?.city
      ? { value: userAddress.city.id, label: userAddress.city.name }
      : null
  );
  const [selectedState, setSelectedState] = useState<any>(
    userAddress?.state
      ? { value: userAddress.state.id, label: userAddress.state.name }
      : null
  );
  const [dob, setDob] = useState(
    userData && userData?.dob
      ? new Date(userData.dob).toISOString().split("T")[0]
      : ""
  );

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenAccount(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();

    let accountObj = {
      mobileNumber: mobile,
      name: name,
      email: email,
      address: address,
      city: {
        id: cities[0].id,
        name: cities[0].name,
      },
      state: {
        id: states[0].id,
        name: states[0].name,
      },
      pincode: pincode,
      dob: "2020-01-02",
    };

    if (userData?.unique_id) {
      // try {
      let response = await axios.put(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/profile`,
        accountObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.data.succ) {
        setSuccess(true);
        handleClose();
        setIsUpdated(true);
        setMsg(response.data.message);
      } else {
        setError(true);
        setMsg(response.data.message);
        console.log("Account Details Error ->", response.data.message);
      }
      // } catch (error) {
      // }
    } else {
      // try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/register`,
        accountObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response.data.succ) {
        setSuccess(true);
        handleClose();
        setIsUpdated(true);
        setMsg(response.data.message);
      } else {
        console.log("Account Details Error ->", response.data.message);
        // setError(true);
        // setMsg(response.data.message);
      }
      // } catch (error) {
      // }
    }
  };

  const handleCityChange = (event: any, city: any) => {
    if (city !== "") {
      fetchCities(city);
    }
  };

  const fetchCities = async (city) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/cities?name=${city}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      data.map((city: any) => {
        return {
          value: city.id,
          label: city.name,
        };
      });
      setCities(data);

      console.log("City Response ->", response.data.data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  const handleStateChange = (event: any, city: any) => {
    if (city !== "") {
      fetchStates(city);
    }
  };

  const fetchStates = async (city) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/states?name=${city}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      data.map((city: any) => {
        return {
          value: city.id,
          label: city.name,
        };
      });
      setStates(data);
    } catch (error) {
      console.log("Fetch State Error ->", error);
    }
  };

  return (
    <Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        open={openAccount}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Account Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Mobile"
              variant="outlined"
              fullWidth
              margin="normal"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />

            <TextField
              type="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />

            <Autocomplete
              fullWidth
              freeSolo
              options={cities.map((s: any) => s.name)}
              value={selectedCity}
              onChange={(e, value) => setSelectedCity(value)}
              onInputChange={(event, newInputValue) =>
                handleCityChange(event, newInputValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  name="selectedCity"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Autocomplete
              fullWidth
              freeSolo
              options={states.map((s: any) => s.name)}
              value={selectedState}
              // onChange={(e, value) => handleState(value)}
              onInputChange={(event, newInputValue) =>
                handleStateChange(event, newInputValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  name="Scheme"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Pincode"
              variant="outlined"
              fullWidth
              margin="normal"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
            <TextField
              type="date"
              id="outlined-basic"
              label="Date of Birth"
              //   variant="outlined"
              focused
              fullWidth
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default AccountModal;
