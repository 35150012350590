import {
  Typography,
  Card,
  CardContent,
  Box,
  TextField,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  Checkbox,
  Button,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  Name_Days,
  isholiday,
  ProductCode,
  metalType,
  purchaseType,
} from "../../../Data/data";
import {
  dateConverter,
  FormatNumberRedeem,
  parseNumber,
} from "../../../utils/formatNumber";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../Store/SocketContext";
import axios from "axios";
import { id } from "date-fns/locale";

export default function GoldRedeem({ accessToken }) {
  const { state }: any = useLocation();
  const { key, value } = state;
  const { socketData } = useContext(SocketContext);

  const [blockId, setBlockId] = useState<string>("");
  const [goldSellPrice, setGoldSellPrice] = useState<number>(0);
  const [silverSellPrice, setSilverSellPrice] = useState<number>(0);
  const [banks, setBanks] = useState<any>();

  useEffect(() => {
    if (socketData) {
      setBlockId(socketData.block_id);

      setGoldSellPrice(socketData.gSell);
      setSilverSellPrice(socketData.sSell);
    }
  }, [socketData]);

  // Fetch User Banks

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank/all`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const res = response.data.data;

        const banks = res.map((bank: any) => {
          return {
            label: `${bank.bankName} (${bank.accountNumber})`,
            value: bank.bank_id,
          };
        });

        setBanks(banks);
      })
      .catch((error) => {
        console.log("error from get user banks ->", error);
      });
  }, []);

  const [totalQuantity, setTotalQuantity] = useState<number>(value.quantity);

  const [isAllUnits, setAllUnits] = useState(false);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState<any>();
  // const [price, setPrice] = useState<string>("0");
  const [qty, setQty] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [bankId, setBankId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [Msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState<boolean>(false);

  const handleChangePurchaseType = (e: any) => {
    let { value } = e.target;
    setSelectedPurchaseType(value);
    setQty(0);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!error) {
      setIsLoading(true);

      const data = {
        blockId: blockId,
        metalType: key,
        lockPrice: key === "silver" ? silverSellPrice : goldSellPrice,
        purchaseType: selectedPurchaseType,
        quantity: selectedPurchaseType === "quantity" ? qty : null,
        amount: selectedPurchaseType === "amount" ? totalAmount : null,
        bankId: bankId,
        totalAmount: totalAmount,
      };

      axios
        .post(`${process.env.REACT_APP_BACKEND_HOST}v1/gold/sell`, data, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          const data = response.data;

          if (!data.succ) {
            setIsFailure(true);
            setIsLoading(false);
            setMsg(data.message);
            return;
          }

          setIsLoading(false);
          setIsSuccess(true);
          setIsLoading(false);
          setMsg(data.message);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsFailure(true);
          // setMsg(data.message);
          return;
        });
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "redeem_units") {
      setAllUnits(false);
      setQty(value);

      let units = value === "" ? 0 : parseNumber(value);

      if (key === "silver" && units > 20000) {
        setErrorMsg("You Cannot Enter More than 20000.");
        setError(true);
      } else if (key === "gold " && units > 1000) {
        setErrorMsg("You Cannot Enter More than 1000.");
        setError(true);
      } else {
        setError(false);
      }

      const approxAmount =
        units * (key === "silver" ? silverSellPrice : goldSellPrice);

      setTotalAmount(approxAmount);
    }

    if (name === "bank") {
      setBankId(value);
    }
  };

  const handleCheck = (event: any) => {
    const { checked } = event.target;

    if (checked) {
      setAllUnits(true);

      setQty(totalQuantity);

      const approxAmount =
        totalQuantity * (key === "silver" ? silverSellPrice : goldSellPrice);

      console.log(approxAmount);

      setTotalAmount(approxAmount);
    } else {
      setAllUnits(false);
      setQty(0);
      setTotalAmount(0);
    }
  };

  const handleCloseSnackbar = () => {
    setIsFailure(false);
  };

  return (
    <>
      <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
        <CardContent>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              Redeem Request
            </Typography>
            <TextField
              label="Metal"
              name="Metal"
              value={key}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              select
              disabled
            >
              {metalType
                .filter((ele) => ele.value == key)
                .map((each, idx) => (
                  <MenuItem key={idx} value={each.value}>
                    {each.label}
                  </MenuItem>
                ))}
            </TextField>
            <Box sx={{ display: "flex", flexDirection: "rows", gap: "10px" }}>
              <TextField
                label="Total Units"
                name="total_investment"
                value={FormatNumberRedeem(totalQuantity)}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
              <TextField
                label="Approx. Value"
                name="total_investment"
                value={FormatNumberRedeem(
                  Number(totalQuantity) *
                    Number(key === "silver" ? silverSellPrice : goldSellPrice)
                )}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
            </Box>
            <TextField
              label="Sell Type"
              name="Sell Type"
              onChange={handleChangePurchaseType}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              select
            >
              {purchaseType.map((ele: any, indx: any) => {
                return (
                  <MenuItem key={indx} value={ele.value}>
                    {ele.label}
                  </MenuItem>
                );
              })}
            </TextField>
            {selectedPurchaseType === "quantity" && (
              <>
                <Box
                  sx={{ display: "flex", flexDirection: "rows", gap: "10px" }}
                >
                  <NumericFormat
                    type="text"
                    label="Redeem Units (in Grams)"
                    name="redeem_units"
                    value={qty}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={error}
                    helperText={errorMsg || "Multiple of 0.001"}
                    required
                    thousandsGroupStyle="lakh"
                    thousandSeparator=","
                    customInput={TextField}
                    decimalScale={3}
                    disabled={isAllUnits}
                  />
                  <TextField
                    label="Approx. Value"
                    value={totalAmount}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled
                  />
                </Box>
                <Checkbox name="checked" onChange={handleCheck} />
                <span>Redeem entire units</span>
              </>
            )}
            {selectedPurchaseType === "amount" && (
              <TextField
                label="Redeem Amount"
                value={totalAmount}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e: any) => setTotalAmount(e.target.value)}

                // error={!!validationErrors.amt_unit}
                // helperText={validationErrors.amt_unit}
              />
            )}
            {selectedPurchaseType !== "" && (
              <TextField
                label="Bank"
                name="bank"
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {banks?.map((ele: any, indx: any) => {
                  return (
                    <MenuItem key={indx} value={ele.value}>
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </CardContent>
        <Snackbar
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => setIsSuccess(false)}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="success">{`Submitted. ${Msg}`}</Alert>
        </Snackbar>
        <Snackbar
          open={isFailure}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="error">{Msg}</Alert>
        </Snackbar>
      </Card>
    </>
  );
}
