import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import CreateTwoToneIcon from "@mui/icons-material/CreateTwoTone";
import { fontWeight } from "@mui/joy/styles/styleFunctionSx";
import BankModal from "../Gold/BankModal";
import AddNewBank from "./AddNewBank";
import AccountModal from "../Gold/AccountModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 900,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProfileWrapper = ({ user, accessToken }) => {
  const [userData, setUserData] = useState<any>(null);
  const [banks, setBanks] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<any>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [openAccount, setOpenAccount] = useState<boolean>(false);

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState("");

  const closeHandleSnackbar = () => {
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    fetchAccountData();
    if (isUpdated) fetchBankData();
  }, [isUpdated]);

  const fetchAccountData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/profile`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      setUserData(data);
      // setIsUpdated(false);
      // console.log("User Response ->", response.data.data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  const fetchBankData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank/all`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let data = response.data.data;
      setBanks(data);
      setIsUpdated(false);
      console.log("Banks Response ->", data);
    } catch (error) {
      console.log("Fetch Cities Error ->", error);
    }
  };

  const updateBank = async (row: any) => {
    setSelectedBank(row);
    setIsOpen(true);
  };

  return (
    <>
      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Mutual Fund
          </Typography>
        </Paper>
      </Grid>
      <Grid spacing={2} sx={{ margin: { xs: 0, sm: 2 } }}>
        <Paper elevation={1} sx={{ p: { xs: 1, sm: 4 } }}>
          <Typography style={{ fontWeight: 600 }} variant="h5" sx={{ mb: 2 }}>
            Gold Investment
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {userData && (
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "text.secondary",
                        }}
                      >
                        Profile
                      </Typography>
                      <IconButton
                        aria-label="editor"
                        color="primary"
                        onClick={() => setOpenAccount(true)}
                      >
                        <CreateTwoToneIcon sx={{ fontSize: 28 }} />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        marginBottom: 2,
                        marginTop: 3,
                      }}
                    >
                      <PersonRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 30 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <PhoneRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.mobile}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <EmailRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.email ? userData.user.email : "-"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <DateRangeRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData.user.dob ? userData.user.dob : ""}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
                      <CreditCardRoundedIcon
                        sx={{ color: "text.secondary", fontSize: 28 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "text.secondary",
                        }}
                      >
                        {userData?.user?.pan?.panNumber}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={8}>
              <Card>
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button variant="contained" onClick={() => setIsOpen(true)}>
                      Add
                    </Button>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Account Name</StyledTableCell>
                          <StyledTableCell align="right">
                            Account Number
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Bank Name
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            IFSC Code
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {banks?.map((row: any) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.accountName}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.accountNumber}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.bankName}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.ifscCode}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <IconButton
                                aria-label="editor"
                                onClick={() => updateBank(row)}
                              >
                                <CreateTwoToneIcon />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {isOpen && (
        <AddNewBank
          isOpenBank={isOpen}
          setIsOpenBank={setIsOpen}
          bank={selectedBank}
          accessToken={accessToken}
          setIsUpdated={setIsUpdated}
          setSelectedBank={setSelectedBank}
        />
      )}
      {openAccount && (
        <AccountModal
          openAccount={openAccount}
          setOpenAccount={setOpenAccount}
          accessToken={accessToken}
          userData={userData?.user}
          userAddress={userData?.address}
          setIsUpdated={setIsUpdated}
          setSuccess={setSuccess}
          setError={setError}
          setMsg={setMsg}
        />
      )}
      <Snackbar
        open={isSuccess}
        autoHideDuration={6000}
        onClose={closeHandleSnackbar}
      >
        <Alert severity="success">{msg}</Alert>
      </Snackbar>
    </>
  );
};

export default ProfileWrapper;
