import { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, Box, TextField } from "@mui/material";
import axios from "axios";
import { useAppContext } from "../../../Store/AppContext";

const BankModal = ({
  isOpenBank,
  setIsOpenBank,
  userData,
  bank,
  accessToken,
}: any) => {
  const [storeState, dispatch] = useAppContext();

  const [account, setAccount] = useState<string>(bank ? bank[0].ACNUM : "");
  const [accountName, setAccountName] = useState<string>(
    userData?.name ? userData?.name : ""
  );

  const [bankName, setBankName] = useState<string>(
    bank ? bank[0].BANK_NAME : ""
  );

  const [ifac, setIfac] = useState<string>(bank ? bank[0].IFSC : "");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setIsOpenBank(false);
  };

  const handleSubmitData = async (e: any) => {
    e.preventDefault();
    let bankObj = {
      bankName: bankName,
      accountNumber: account,
      accountName: accountName,
      ifscCode: ifac,
    };

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/gold/bank`,
        bankObj,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("Bank response ->", response.data);
      handleClose();
    } catch (error) {
      console.log("bank Details Error ->", error);
    }
  };

  return (
    <Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <Dialog
        fullScreen={fullScreen}
        open={isOpenBank}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add Bank Details</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitData}>
            <TextField
              type="text"
              id="outlined-basic"
              label="Bank Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />

            <TextField
              type="number"
              id="outlined-basic"
              label="Account Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Account Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />

            <TextField
              type="text"
              id="outlined-basic"
              label="Ifac Code"
              variant="outlined"
              margin="normal"
              fullWidth
              value={ifac}
              onChange={(e) => setIfac(e.target.value)}
            />
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
export default BankModal;
